import React, { useRef } from 'react'
import Swipeable from 'react-native-gesture-handler/Swipeable'
import { View, Animated, ActivityIndicator } from 'react-native'
import { colors, translate, hooks } from '@pay24/common'
import Icon from './Icon'
import { Text } from '@ui-kitten/components'

const { useTranslation } = translate
const { useTheme } = hooks

type Props = {
  disabled?: boolean
  title: string
  submitApplication?: () => void
  builderCreatedInvoice?: boolean
  loading?: boolean
  backgroundText?: string
  onSwipe: () => void
}

export const SwipeableButton = ({
  disabled,
  loading,
  title,
  backgroundText,
  onSwipe,
}: Props) => {
  const swipeable = useRef<any>()
  const { t } = useTranslation()
  const { isLight, primary } = useTheme()

  const Container = disabled ? View : Swipeable

  const LeftActions = (progress, dragX) => {
    const scale = dragX.interpolate({
      inputRange: [0, 100],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    })
    return (
      <View
        style={{
          backgroundColor: primary,
          borderRadius: 8,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
          paddingHorizontal: 10,
        }}
      >
        {backgroundText ? (
          <Animated.Text
            style={{
              color: 'white',
              fontWeight: '600',
              textAlign: 'center',
              transform: [{ scale }],
              flex: 1,
            }}
          >
            {t(backgroundText)}
          </Animated.Text>
        ) : null}
        <View style={{ justifyContent: 'flex-end' }}>
          <Icon name={'chevron-double-right'} color={colors.white} />
        </View>
      </View>
    )
  }

  return (
    <Container
      ref={swipeable}
      renderLeftActions={LeftActions}
      leftThreshold={260}
      rightThreshold={260}
      onSwipeableLeftOpen={onSwipe}
    >
      <View
        style={{
          backgroundColor: isLight ? colors.borderLight : colors.borderDark,
          borderRadius: 8,
          flexDirection: 'row',
          alignItems: 'center',
          height: 42,
          paddingRight: 48,
          opacity: disabled ? 0.4 : 1,
        }}
      >
        <View
          style={{
            backgroundColor: primary,
            padding: 5,
            width: 68,
            height: 42,
            borderRadius: 8,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <ActivityIndicator
              testID="indicator"
              color={'white'}
              style={{ marginHorizontal: 10 }}
            />
          ) : (
            <Icon name={'chevron-double-right'} color={colors.white} />
          )}
        </View>
        <Text
          style={{
            fontSize: 14,
            fontWeight: '500',
            flex: 1,
            textAlign: 'center',
            color: primary,
          }}
        >
          {t(title)}
        </Text>
      </View>
    </Container>
  )
}
